<template>
  <div>
    <page-title
      :heading="$t('generic.lang_replication_templates')"
      :subheading="$t('generic.lang_replication_templates')"
      :icon="icon"
      show-add-button
      permission-add
      url-add="/posReplication/templates/create"
    ></page-title>
    <div class="app-main__inner">
      <replication-template-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import ReplicationTemplateComponent from "../../components/posReplication/replicationTemplate/ReplicationTemplateComponent.vue";

export default {
  name: "ReplicationTemplateView",
  components: {
    PageTitle,
    ReplicationTemplateComponent,
  },

  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
