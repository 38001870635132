<template>
  <Datatable
    :api-endpoint="ENDPOINTS.DATATABLES.POS_REPLICATION.REPLICATION_TEMPLATE"
    :datatable-headers="this.datatableHeaders"
    :excel-columns="excelColumns"
    excel-file-name="replicationTemplates"
    :v-show="true"
    @deleteEntries="deleteEntries"
    @deleteEntry="deleteEntry"
    @editEntry="editEntry"
    show-delete-buttons
    show-edit-buttons
    show-pdf
    show-select-checkbox
    ref="replicationTemplate"
  />
</template>

<script>
import Datatable from "../../datatable/Datatable";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "ReplicationTemplateComponent",
  components: { Datatable },

  data: () => {
    return {
      ENDPOINTS,
    };
  },
  computed: {
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          sortable: false,
          hide: true,
        },
        {
          text: this.$t("erp.lang_TemplateName"),
          value: "item_name",
          sortable: false,
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t("erp.lang_TemplateName"),
          field: "item_name",
        },
      ];
    },
  },

  methods: {
    editEntry(entry) {
      this.$router.push("/posReplication/templates/edit/" + entry.id);
    },
    deleteEntry(entry) {
      this.delete([entry.id]);
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t("generic.lang_deleteReplicationTmpl"),
        text: this.$t("erp.lang_deletePriceTmplText"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.POS_REPLICATION.REPLICATION_TEMPLATE.DELETE, {
              ids: idsToDelete,
            })
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_success"),
                  color: "success",
                });

                this.$refs.replicationTemplate.getDataFromApi();
                this.$refs.replicationTemplate.resetSelectedRows();
              } else if (res.data.status === "ACTIVE") {
                this.$swal({
                  title: this.$t("erp.lang_deletePriceTemplErrorHead"),
                  text: this.$t("generic.lang_deleteReplicationTemplErrorBody"),
                  icon: "warning",
                });
              } else {
                Events.$emit("showSnackbar", {
                  color: "error",
                  message: this.$t("generic.lang_anErrorOccurredPLSTryAgain"),
                });
              }
            })
            .catch((err) => {
              Events.$emit("showSnackbar", {
                color: "error",
                message: err.message,
              });
            });
        },
      });
    },
  },
};
</script>
